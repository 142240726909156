import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';

import {SessionProvider} from 'next-auth/react';
import React, { useState } from 'react';
import RefreshTokenHandler from '../components/refreshTokenHandler';
import {useRouter} from "next/router";
import {ToastContainer} from "react-toastify";
import { Analytics } from '@vercel/analytics/react';
import Head from 'next/head';


export default function App({Component, pageProps: { session, ...pageProps }, }) {
    const router = useRouter();
    const DEBUG = process.env.DEBUG;

    if (session === null) {
        if (router.route !== '/authentication/signin') {
            router.replace('/authentication/signin');
        }
    }
    const [interval, setInterval] = useState(0);

    return (
        <SessionProvider session={session} refetchInterval={interval}>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Component {...pageProps} />
            { !DEBUG ? <Analytics /> : ''}
            <RefreshTokenHandler setInterval={setInterval} />
            <ToastContainer/>
        </SessionProvider>
    )
}


